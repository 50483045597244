.section--first {
    padding-top: 72px;
    padding-bottom: 122px;

    @include lg-max {
        padding-top: 52px;
        padding-bottom: 57px;
    }
}

.catalog {
    &__items {
        @include lg-max {
            margin-left: -4.5px;
            margin-right: -4.5px;
        }
    }
    
    &__item {
        margin-bottom: 22px;

        @include lg-max {
            padding-left: 4.5px;
            padding-right: 4.5px;
            margin-bottom: 9px;
        }
    }

    &__block {
        text-align: center;
        background-color: var(--color-tundora);
        padding: 13px 20px 34px;
        color: var(--color-white);
        border-radius: 8px;
        transition: all .3s;

        &:hover {
            background-color: var(--color-dark);
        }

        @include lg-max {
            padding-bottom: 23px;
        }
    }
    
    &__icon {
        color: var(--color-sliver);
        height: 132px;

        @include lg-max {
            height: 102px;
        }
    }

    &__text {
        @include lg-max {
            font-size: 17px;
            line-height: 1.4117;
        }
    }
}

.icon {
    &__svg {
        &--catalog-1 {
            width: 90px;
            height: 68px;
            margin-top: 37px;

            @include lg-max {
                margin-top: 17px;
                width: 76px;
            }
        }

        &--catalog-2 {
            width: 136px;
            height: 80px;
            margin-top: 31px;

            @include lg-max {
                margin-top: 5px;
                width: 111px;
            }
        }

        &--catalog-3 {
            width: 84px;
            height: 81px;
            margin-top: 25px;

            @include lg-max {
                margin-top: 13px;
                width: 68px;
            }
        }

        &--catalog-4 {
            width: 121px;
            height: 99px;
            margin-top: 12px;

            @include lg-max {
                margin-top: 3px;
                width: 102px;
            }
        }

        &--catalog-5 {
            width: 70px;
            height: 91px;
            margin-top: 20px;

            @include lg-max {
                margin-top: 6px;
                width: 56px;
            }
        }

        &--catalog-6 {
            width: 44px;
            height: 111px;

            @include lg-max {
                margin-top: 3px;
                height: 86px;
            }
        }
    }
}