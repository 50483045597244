.section--stages {
    color: var(--color-white);
    padding: 132px 0 202px;

    @include lg-max {
        padding-top: 48px;
        padding-bottom: 50px;
    }
}

.swiper-slide--stages {
    padding-left: 7.5px;
    padding-right: 7.5px;

    @include lg-min {
        &::before {
            content: '';
            height: 17px;
            width: 59px;
            background: url(../img/arrow-right.png) no-repeat top left;
            position: absolute;
            right: -24px;
            top: 31px;
            z-index: 50;
        }

        &:last-of-type::before {
            display: none;
        }
    }

    @include lg-max {
        width: 305px;
    }
}

.stages {
    margin-left: -7.5px;
    margin-right: -7.5px;

    &__dots {
        @include lg-min {
            margin-top: 30px;
        }
    }
}

.stage {
    background-color: var(--color-white);
    border-radius: 8px;
    padding: 36px 18px 34px;
    color: #0c0c0c;
    position: relative;
    z-index: 1;

    &::before {
        content: attr(data-count);
        color: var(--color-wild-sand);
        font-size: 66px;
        font-family: "HelveticaNeueCyr", sans-serif;
        font-weight: 300;
        position: absolute;
        bottom: 11px;
        right: 23px;
        z-index: -1;
    }
    
    &__icon {
        height: 72px;
        width: 72px;
        border-radius: 50%;
        background-color: var(--color-tundora);
        color: var(--color-white);
        margin-bottom: 29px;
    }

    &__text {
        font-size: 15px;
        line-height: 1.467;
    }
}

.icon {
    &__svg--step-1 {
        height: 24px;
        width: 24px;
    }

    &__svg--step-2 {
        height: 24px;
        width: 25px;
    }

    &__svg--step-3 {
        height: 29px;
        width: 30px;
    }

    &__svg--step-4 {
        height: 30px;
        width: 19px;
    }

    &__svg--step-5 {
        height: 28px;
        width: 24px;
    }
}