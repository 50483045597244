.page__row--footer {
    background-color: var(--color-shark);
    color: var(--color-white);
}

.social {

    &__link {
        display: block;
    }

    &__text {
        padding-bottom: 4px;
    }

    &__picture {
        &.telegram {
            height: 28px;
            width: 28px;
        }
        
        &.viber {
            width: 28px;
        }
        
        &.inst {
            height: 26px;
            width: 26px;
        }
        
        &.fb {
            height: 26px;
            width: 26px;
        }
    }
}

.footer {
    &__row {
        @include lg-max {
            &--social {
                margin-left: -8px;
                margin-right: -8px;
            }
        }
    }

    &__cell {
        @include lg-max {
            &--social {
                padding-left: 8px;
                padding-right: 8px;
            }
        }
    }

    &__top {
        padding: 57px 0 25px;
        border-bottom: 1px solid rgba(255, 255, 255, .04);
    }

    &__middle {
        border-bottom: 1px solid rgba(255, 255, 255, .04);

        @include lg-max {
            padding-bottom: 20px;
        }
    }

    &__bottom {
        font-size: 14px;
        padding: 24px 0 33px;

        @include lg-max {
            color: #79797a;
            line-height: 1.1429;
            padding-bottom: 42px;
        }
    }

    &-logo {
        margin-bottom: 29px;
        text-align: left;
    }

    &__cell {
        &--contacts {
            @include lg-min {
                margin-right: 116px;
            }
            
            &:last-of-type {margin-right: 0;}
        }
    }

    &__nav {

        @include lg-max {
            &-menu {
                display: none;

                &.active {
                    display: block;
                }
            }
        }
        
        &-link {
            color: var(--color-white);
            display: block;
            padding: 29px 12px 32px;
            font-size: 16px;
            transition: all .3s;
            &:hover {
                opacity: .4;
            }

            @include lg-max {
                padding: 10px 15px 7px;
                margin: 0 -15px;
            }
        }

        &-btn {
            font-size: 16px;
            color: #787878;
            text-transform: uppercase;
            letter-spacing: .8px;
            padding: 33px 15px 18px;
            margin-left: -15px;
            margin-right: -15px;
            position: relative;
            z-index: 1;
            width: calc(100% + 30px);
            text-align: left;
        }

        &-icon {
            position: absolute;
            right: 15px;
            z-index: 1;
            top: 40px;
        }
    }

    &__copy {
        @include xl-max {
            margin-bottom: 23px;
        }

        @include lg-max {
            margin-bottom: 13px;
        }
    }

    &__separator {
        margin: 0 3px;
    }
    
    &__privacy-policy {
        @include lg-min {
            margin-right: 82px;
        }

        @include lg-max {
            margin-bottom: 42px;
        }

        &-link {
            color: var(--color-white);

            @include lg-max {
                color: inherit;
            }
        }
    }
}

.footer, .nav {

    &__contacts-block {
        margin-bottom: 17px;

        @include lg-max {
            margin-bottom: 19px;
        }
    }

    &__contacts-icon {
        color: inherit;
        background-repeat: no-repeat;
        width: 20px;
        min-height: 22px;
        margin-right: 10px;
        position: relative;
        z-index: 1;
        
        &.tel {
            background-image: url(../img/footer-tel-number.svg);
            background-size: 13px 21px;

            @include lg-min {
                top: 2px;
            }
        }
        &.email {
            background-image: url(../img/footer-email.svg);
            background-size: 20px 20px;
            top: 1px;
            left: -1px;

            @include lg-max {
                top: -1px;
                left: -3px;
            }
        }
        &.pin {
            background-image: url(../img/footer-pin.svg);
            background-size: 17px 22px;
            top: 1px;

            @include lg-max {
                top: -1px;
            }
        }
    }

    &__contacts-text {

        color: var(--color-dark);
        font-size: 15px;
    }
}

.footer {

    &__contacts-text {
        font-size: 17px;
        color: var(--color-white);
    }
}