:root {
  --font-family: "Montserrat";
  --header-height: 132px;
  --container-width: 1360px;
  --container-offset: 15px;
  --color-white: #fff;
  --color-dark: #222;
  --color-tundora: #4B4B4B;
  --color-mine-shaft: #383838;
  --color-mine-shaft-2: #3F3F3F;
  --color-wild-sand: #F4F4F4;
  --color-sliver: #B9B9B9;
  --color-shark: #2A2A2C;
  --color-dove-gray: #707070;
  --color-jumbo: #79797A;
  --color-nobel: #b6b6b6;
  --color-alabaster: #F9F9F9;
}

@media (max-width: 991px) {
  :root {
    --header-height: 95px;
  }
}
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueCyr";
  src: url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueCyr";
  src: url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 17px;
  line-height: 1.4118;
  color: var(--color-tundora);
  letter-spacing: 0.2px;
}
@media (max-width: 991px) {
  body {
    font-size: 15px;
    line-height: 1.3333;
    overflow-x: hidden;
  }
  body.active {
    overflow: hidden;
  }
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.title {
  font-family: "HelveticaNeueCyr", sans-serif;
}
.title--mrb-0 {
  margin-bottom: 0 !important;
}
.title--h1 {
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: 0.6px;
}
@media (max-width: 991px) {
  .title--h1 {
    font-size: 31px;
  }
}
.title--h2 {
  font-size: 50px;
  line-height: 1.44;
  letter-spacing: 0.6px;
}
@media (max-width: 991px) {
  .title--h2 {
    font-size: 28px;
    line-height: 1.5089;
    letter-spacing: 0.2px;
  }
  .title--h2-mobile {
    line-height: 1.215;
  }
}
.title--h4 {
  font-size: 25px;
  line-height: 1.52;
  letter-spacing: 0.6px;
}
@media (max-width: 991px) {
  .title--h4 {
    font-size: 17px;
    line-height: 1.3118;
  }
}

.regular {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

.light {
  font-weight: 300;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left;
}

@media (max-width: 991px) {
  .text-left-mobile {
    text-align: left !important;
  }
}

.text-center {
  text-align: center;
}

@media (max-width: 991px) {
  .text-center-mobile {
    text-align: center;
  }
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.bg-color-alabaster {
  background-color: var(--color-alabaster);
}

.bg-color-tundora {
  background-color: var(--color-tundora);
}

@media (max-width: 991px) {
  .bg-color-wild-sand-mobile {
    background-color: var(--color-wild-sand);
  }
}

.bg-overlay {
  position: relative;
  z-index: 1;
}
.bg-overlay::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: rgba(12, 12, 12, 0.65);
}

.object-fit-wrapper {
  position: relative;
  z-index: 1;
  display: block;
  overflow: hidden;
}

.object-fit-cover, .object-fit-contain {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  -ms-transform: scale(1) perspective(600px);
  transform: scale(1) perspective(600px);
  -ms-transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-wrapper:hover .object-fit-cover, .object-fit-wrapper:hover .object-fit-contain {
  transform: scale(1.1) perspective(600px);
}

.color-dark {
  color: var(--color-dark);
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.icon__svg--social {
  color: var(--color-jumbo);
  height: 28px;
  width: 28px;
}

@media (min-width: 1440px) {
  .container {
    max-width: var(--container-width);
  }
}
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.dis-scroll {
  position: relative;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

.page {
  height: 100vh;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

.overflow-hidden {
  overflow: hidden;
}

.user-select-none {
  user-select: none;
}

.pointer-events-none {
  pointer-events: none;
}

.height-fluid {
  height: 100%;
}

.row-mr-0 {
  margin-left: 0;
  margin-right: 0;
}

.row-mr-8 {
  margin-left: -8px;
  margin-right: -8px;
}

.row-mr-11 {
  margin-left: -11px;
  margin-right: -11px;
}

.row-mr-12 {
  margin-left: -12px;
  margin-right: -12px;
}

.row-mr-16 {
  margin-left: -16px;
  margin-right: -16px;
}

.row-mr-42 {
  margin-left: -42px;
  margin-right: -42px;
}

.col-pd-0 {
  padding-left: 0;
  padding-right: 0;
}

.col-pd-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.col-pd-11 {
  padding-left: 11px;
  padding-right: 11px;
}

.col-pd-12 {
  padding-left: 12px;
  padding-right: 12px;
}

.col-pd-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.col-pd-42 {
  padding-left: 42px;
  padding-right: 42px;
}

.text {
  letter-spacing: 0.2px;
  margin-bottom: 59px;
}
.text--24 {
  margin-bottom: 24px;
}
@media (max-width: 991px) {
  .text--24 {
    margin-bottom: 20px;
  }
}

.section--default {
  padding-top: 94px;
  padding-bottom: 100px;
}
@media (max-width: 991px) {
  .section--default {
    padding-top: 44px;
  }
}
.section__header {
  padding: 42px 0 45px;
}
.section__cell--content {
  margin-bottom: 62px;
}
@media (min-width: 1200px) {
  .section__cell--content {
    padding-top: 24px;
  }
}
@media (max-width: 991px) {
  .section__cell--content {
    margin-bottom: 52px;
  }
}
.section__cell--advantages {
  padding-top: 75px;
  background: url("../img/advantages-arrow.png") no-repeat calc(100% - 37px) 8px;
}
@media (max-width: 991px) {
  .section__cell--advantages {
    background: none;
    padding-top: 0;
  }
}
@media (min-width: 1440px) {
  .section__cell--text {
    padding-top: 28px;
  }
}
@media (min-width: 992px) {
  .section__cell--text {
    padding-bottom: 40px;
  }
}
.section__cell--image {
  margin-bottom: 22px;
}
.section__main-title {
  font-size: 64px;
  line-height: 1.202;
  color: var(--color-dark);
  letter-spacing: 1.4px;
  margin-bottom: 20px;
}
@media (min-width: 1200px) {
  .section__main-title {
    font-size: 104px;
    margin-bottom: 62px;
  }
}
@media (max-width: 991px) {
  .section__main-title {
    font-size: 58px;
    margin-bottom: 16px;
  }
}
.section__title {
  margin-bottom: 66px;
}
@media (max-width: 991px) {
  .section__title {
    margin-bottom: 28px;
  }
}
.section__picture {
  border-radius: 8px;
}
@media (max-width: 991px) {
  .section__text {
    line-height: 1.6;
    margin-bottom: 20px;
  }
}
.section__btn-wrapper {
  padding: 63px 15px 71px;
  text-align: center;
}

@media (min-width: 1440px) {
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .d-xxl-block {
    display: block !important;
  }
}
.btn {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  position: relative;
  z-index: 1;
  font-size: 19px;
  line-height: 1;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  border-radius: 5px;
  transition: all 0.3s ease-out;
}
.btn--default {
  border-color: var(--color-white);
  color: var(--color-white);
}
.btn--default:hover {
  background-color: var(--color-white);
  color: var(--color-tundora);
}
.btn--primary {
  border-color: var(--color-dark);
  color: var(--color-dark);
}
.btn--primary:hover {
  background-color: var(--color-dark);
  color: var(--color-white);
}
.btn--sm {
  height: 43px;
  font-size: 15px;
  min-width: 150px;
}
.btn--lg {
  height: 70px;
  min-width: 221px;
  font-size: 17px;
  letter-spacing: 0.2px;
  padding-left: 21px;
  padding-right: 21px;
}
@media (max-width: 991px) {
  .btn--lg {
    min-width: 290px;
  }
}
@media (min-width: 992px) {
  .btn--xl {
    height: 112px;
    min-width: 293px;
    font-size: 17px;
    line-height: 1;
    padding-left: 30px;
    padding-right: 30px;
    letter-spacing: 0.2px;
  }
}
@media (max-width: 991px) {
  .btn--xl {
    height: 90px;
    padding-bottom: 3px;
    min-width: 233px;
    line-height: 1.4118;
    font-size: 17px;
  }
}
.btn--fluid {
  width: 100%;
  max-width: 100%;
  min-width: 0;
}

.icon--fill-none {
  fill: none;
}
.icon--stroke-none {
  stroke: none;
}
.icon__svg {
  fill: currentColor;
  stroke: currentColor;
}
.icon__svg--btn-arrow {
  margin-left: 50px;
  margin-right: 2px;
  height: 30px;
  width: 31px;
  stroke-width: 6px;
}
@media (max-width: 991px) {
  .icon__svg--btn-arrow {
    margin-left: 27px;
    margin-right: 0;
  }
}
.icon .fill-none {
  fill: none;
}
.icon .stroke-none {
  stroke: none;
}

.home__dots.dots {
  position: absolute;
  top: 300px;
  left: auto;
  bottom: auto;
  width: 20px;
  z-index: 100;
}
@media (min-width: 992px) {
  .home__dots.dots {
    right: calc(50% - 430px);
  }
}
@media (min-width: 1200px) {
  .home__dots.dots {
    right: calc(50% - 550px);
  }
}
@media (min-width: 1440px) {
  .home__dots.dots {
    right: calc(50% - 645px);
  }
}
@media (max-width: 991px) {
  .home__dots.dots {
    width: auto;
    bottom: 47px;
    left: 50%;
    transform: translateX(-50%);
    top: auto;
  }
}

.swiper-slide {
  height: auto;
}

@media (max-width: 991px) {
  .dots {
    padding-top: 27px;
  }
}
.dots .swiper-pagination-bullet {
  height: 13px;
  width: 13px;
  margin: 0;
  padding: 4px;
  background-clip: content-box;
  background-color: var(--color-white);
  opacity: 0.4;
  transition: all 0.3s;
}
@media (max-width: 991px) {
  .dots .swiper-pagination-bullet {
    margin: 0 1px !important;
  }
}
.dots .swiper-pagination-bullet-active {
  opacity: 1;
  transform: scale(1.5);
}
.dots--gray .swiper-pagination-bullet {
  background-color: #d2d2d2;
}

.carousel-nav {
  margin-right: -20px;
}

.prev, .next {
  color: #0f0f0f;
  padding: 10px;
  cursor: pointer;
  margin: 0 10px;
}

.prev__icon {
  transform: rotate(180deg);
}

.prev__icon, .next__icon {
  height: 45px;
  width: 41px;
}

.swiper-overflow-visible {
  overflow: visible;
}

.breadcrumbs__items {
  color: rgba(34, 34, 34, 0.52);
  line-height: 1.4667;
  font-size: 13px;
}

.breadcrumbs__items--top {
  color: rgba(255, 255, 255, 0.52);
}

.breadcrumbs__item {
  display: inline;
  letter-spacing: 1.3px;
}

.breadcrumbs__item + .breadcrumbs__item::before {
  content: "";
  margin: 0 9px 0 5px;
  font-size: inherit;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  height: 10px;
  width: 20px;
  background: url(../img/breadcrumb-arrow.png) no-repeat top left;
}

.breadcrumbs__link {
  color: #222;
}

.breadcrumbs__link--top {
  color: var(--color-white);
}

.page__row--header {
  background-color: var(--color-mine-shaft);
  height: var(--header-height);
}
.page__row--header-abs {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
@media (max-width: 991px) {
  .page__row--header {
    height: auto;
  }
}

.header {
  padding: 28px 0 40px;
}
@media (max-width: 991px) {
  .header {
    padding: 0;
  }
}
@media (max-width: 991px) {
  .header__row {
    margin-left: -5px;
    height: var(--header-height);
    margin-right: -5px;
  }
}
@media (max-width: 991px) {
  .header__cell {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.header__cell--logo {
  margin-right: 80px;
}
@media (max-width: 1439px) {
  .header__cell--logo {
    margin-right: 40px;
  }
}
@media (max-width: 991px) {
  .header__cell--logo {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .header__cell--nav {
    position: absolute;
    z-index: 1000;
  }
}

.logo, .footer-logo {
  color: var(--color-white);
  position: relative;
  z-index: 1;
}
.logo--nav, .footer-logo--nav {
  color: inherit;
}
.logo__picture, .footer-logo__picture {
  margin-right: 22px;
  position: relative;
  top: -1px;
  width: 77px;
}
.logo__title, .footer-logo__title {
  display: block;
  font-size: 18px;
  font-family: "HelveticaNeueCyr", sans-serif;
  font-weight: 700;
  letter-spacing: 0.2px;
  margin-bottom: 3px;
}
.logo__desc, .footer-logo__desc {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.2px;
}

@media (max-width: 991px) {
  .logo {
    width: 170px;
  }
}
@media (max-width: 991px) {
  .logo__picture {
    width: 66px;
    margin-right: 8px;
  }
}
.logo__picture--nav {
  width: 100px;
  margin-right: 16px;
}
@media (max-width: 991px) {
  .logo__title {
    line-height: 1.2;
    font-size: 15px;
  }
}
.logo__title--nav {
  font-size: 16px;
  margin-bottom: 0;
  padding-top: 4px;
}

.nav {
  color: var(--color-white);
  padding-top: 11px;
}
@media (max-width: 1439px) {
  .nav {
    font-size: 14px;
    padding: 0 15px;
  }
}
@media (max-width: 991px) {
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #fafafa;
    color: var(--color-dark);
    height: 100vh;
    max-height: 100%;
    overflow: auto;
    margin: 0;
    padding: 0 0 30px;
    max-width: 252px;
    width: 100%;
    -ms-transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -ms-transition: left 0.5s ease;
    -webkit-transition: left 0.6s cubic-bezier(0.4, 0.2, 0, 1);
    transition: left 0.6s cubic-bezier(0.4, 0.2, 0, 1);
  }
  .nav.active {
    left: 252px;
  }
}
@media (max-width: 991px) {
  .nav__menu {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    margin: 0 -15px;
  }
}
.nav__item {
  margin-right: 47px;
}
@media (max-width: 1439px) {
  .nav__item {
    margin-right: 30px;
  }
}
@media (max-width: 991px) {
  .nav__item {
    margin-right: 0;
    border-bottom: 1px solid #f0f0f0;
  }
  .nav__item:last-of-type {
    border-bottom: none;
  }
}
.nav__item:last-of-type {
  margin-right: 0;
}
.nav__link {
  color: inherit;
  transition: all 0.2s;
}
.nav__link:hover {
  color: rgba(255, 255, 255, 0.85);
}
@media (max-width: 991px) {
  .nav__link {
    font-size: 17px;
    padding: 15px 15px 16px;
    display: block;
  }
  .nav__link:hover {
    color: inherit;
  }
}
@media (max-width: 991px) {
  .nav__top {
    background-color: var(--color-white);
    padding-top: 32px;
    margin-bottom: 60px;
  }
}
.nav__logo {
  margin-bottom: 39px;
}
.nav__tel {
  color: inherit;
}
@media (max-width: 1439px) {
  .nav__tel {
    font-size: 14px;
  }
}
@media (max-width: 1439px) {
  .nav__btn {
    min-width: auto;
  }
}
.nav__contacts-block {
  margin-bottom: 22px;
}
.nav__contacts-icon {
  min-height: 20px;
}
.nav__contacts-icon.tel {
  background-position: 1px 0;
  background-size: 13px 21px;
  font-size: 15px;
}
.nav__contacts-icon.email {
  background-size: 20px 20px;
}
.nav__contacts-icon.pin {
  background-size: 17px 22px;
}
@media (max-width: 991px) {
  .nav__overlay {
    background-color: rgba(0, 0, 0, 0.62);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: z-index 0.3s linear, opacity 0.3s linear, visibility 0.3s linear;
  }
  .nav__overlay.active {
    z-index: 500;
    opacity: 1;
    visibility: visible;
  }
}

.btn-tel {
  height: 38px;
  width: 38px;
  border: 1px solid rgba(255, 255, 255, 0.27);
  border-radius: 50%;
  position: relative;
  top: -3px;
  margin-right: 13px;
}
.btn-tel__icon {
  width: 15px;
}

.hamburger {
  padding: 13px;
  margin-right: -13px;
}

.home {
  color: var(--color-white);
}
.home .swiper-slide {
  height: 100%;
}
.home__item {
  position: relative;
  z-index: 1;
}
.home__item::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(to bottom, rgba(12, 12, 12, 0.71) 0%, rgba(12, 12, 12, 0.6) 1%, rgba(13, 13, 13, 0.38) 94%, rgba(11, 11, 11, 0.36) 100%);
}
.home__wrapper {
  height: 100vh;
  min-height: 600px;
  max-height: 796px;
  padding-bottom: 109px;
}
@media (max-width: 991px) {
  .home__wrapper {
    padding-top: 150px;
    min-height: 480px;
  }
}
.home__content {
  max-width: 766px;
}
@media (max-width: 991px) {
  .home__content {
    text-align: center;
  }
}
.home__title {
  margin-top: 18px;
  margin-bottom: 34px;
}
@media (max-width: 991px) {
  .home__title {
    margin-top: 12px;
  }
}
.home__text {
  font-size: 13px;
  display: block;
  letter-spacing: 1.2px;
}

@media (min-width: 992px) {
  .disc-num.swiper-pagination-bullets {
    right: calc(50% - 450px);
  }
}
@media (min-width: 1200px) {
  .disc-num.swiper-pagination-bullets {
    right: calc(50% - 550px);
  }
}
@media (min-width: 1440px) {
  .disc-num.swiper-pagination-bullets {
    right: calc(50% - 665px);
  }
}

.disc-num.swiper-pagination-bullets {
  counter-reset: slides-num;
  color: #fff;
  position: absolute;
  bottom: 78px;
  left: auto;
  z-index: 50;
  text-align: left;
  padding-left: 50px;
  width: auto;
  font-size: 18px;
}

.disc-num::before, .disc-num::after {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  position: relative;
  top: -3px;
}

.disc-num::before {
  content: "/";
  color: inherit;
}

.disc-num::after {
  content: counter(slides-num, decimal-leading-zero);
  font-size: 14px;
  margin-left: 3px;
  letter-spacing: 1px;
}

.disc-num .swiper-pagination-bullet {
  display: inline-block;
  cursor: default;
  counter-increment: slides-num;
  margin-right: 5px;
  position: absolute;
  left: 0;
  top: 0;
  background: none;
}

.disc-num .swiper-pagination-bullet::after {
  content: counter(slides-num, decimal-leading-zero);
  font-size: 18px;
  line-height: 1;
  text-align: right;
  position: absolute;
  right: -37px;
  top: 0;
  opacity: 0;
  z-index: 50;
  letter-spacing: 1px;
}

.disc-num .swiper-pagination-bullet-active::after {
  opacity: 1;
  z-index: 100;
}

.disc-num .swiper-pagination-bullet::before {
  display: none;
}

.section--first {
  padding-top: 72px;
  padding-bottom: 122px;
}
@media (max-width: 991px) {
  .section--first {
    padding-top: 52px;
    padding-bottom: 57px;
  }
}

@media (max-width: 991px) {
  .catalog__items {
    margin-left: -4.5px;
    margin-right: -4.5px;
  }
}
.catalog__item {
  margin-bottom: 22px;
}
@media (max-width: 991px) {
  .catalog__item {
    padding-left: 4.5px;
    padding-right: 4.5px;
    margin-bottom: 9px;
  }
}
.catalog__block {
  text-align: center;
  background-color: var(--color-tundora);
  padding: 13px 20px 34px;
  color: var(--color-white);
  border-radius: 8px;
  transition: all 0.3s;
}
.catalog__block:hover {
  background-color: var(--color-dark);
}
@media (max-width: 991px) {
  .catalog__block {
    padding-bottom: 23px;
  }
}
.catalog__icon {
  color: var(--color-sliver);
  height: 132px;
}
@media (max-width: 991px) {
  .catalog__icon {
    height: 102px;
  }
}
@media (max-width: 991px) {
  .catalog__text {
    font-size: 17px;
    line-height: 1.4117;
  }
}

.icon__svg--catalog-1 {
  width: 90px;
  height: 68px;
  margin-top: 37px;
}
@media (max-width: 991px) {
  .icon__svg--catalog-1 {
    margin-top: 17px;
    width: 76px;
  }
}
.icon__svg--catalog-2 {
  width: 136px;
  height: 80px;
  margin-top: 31px;
}
@media (max-width: 991px) {
  .icon__svg--catalog-2 {
    margin-top: 5px;
    width: 111px;
  }
}
.icon__svg--catalog-3 {
  width: 84px;
  height: 81px;
  margin-top: 25px;
}
@media (max-width: 991px) {
  .icon__svg--catalog-3 {
    margin-top: 13px;
    width: 68px;
  }
}
.icon__svg--catalog-4 {
  width: 121px;
  height: 99px;
  margin-top: 12px;
}
@media (max-width: 991px) {
  .icon__svg--catalog-4 {
    margin-top: 3px;
    width: 102px;
  }
}
.icon__svg--catalog-5 {
  width: 70px;
  height: 91px;
  margin-top: 20px;
}
@media (max-width: 991px) {
  .icon__svg--catalog-5 {
    margin-top: 6px;
    width: 56px;
  }
}
.icon__svg--catalog-6 {
  width: 44px;
  height: 111px;
}
@media (max-width: 991px) {
  .icon__svg--catalog-6 {
    margin-top: 3px;
    height: 86px;
  }
}

@media (max-width: 991px) {
  .section--works {
    padding-top: 42px;
  }
}

@media (max-width: 991px) {
  .works__dots {
    padding-bottom: 30px;
  }
}

.work {
  background-color: var(--color-mine-shaft-2);
  background-repeat: no-repeat;
  color: var(--color-white);
}
.work::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: url(../img/work-overlay.png) no-repeat top left;
  background-size: cover;
}
.work__picture {
  z-index: -2;
}
@media (max-width: 1439px) {
  .work__btn {
    min-width: 100%;
  }
}
.work__link {
  width: 100%;
  display: block;
  padding-bottom: 139%;
}
.work__content {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
}
.work__content, .work__wrapper {
  padding: 72px 46px 79px;
}
.work__content {
  padding-top: 0;
  padding-bottom: 0;
  bottom: 79px;
}
@media (max-width: 991px) {
  .work__content {
    padding-left: 29px;
    padding-right: 29px;
    bottom: 33px;
  }
}
.work__text {
  margin-bottom: 12px;
  padding-left: 56px;
  position: relative;
  z-index: 1;
  color: var(--color-nobel);
  font-size: 15px;
}
.work__text::before {
  content: "";
  width: 40px;
  height: 1px;
  background-color: #a9a9a9;
  position: absolute;
  left: 0;
  top: 9px;
  z-index: 1;
}
@media (max-width: 991px) {
  .work__text::before {
    width: 23px;
    top: 7px;
  }
}
@media (max-width: 991px) {
  .work__text {
    font-size: 12px;
    padding-left: 35px;
    margin-bottom: 5px;
  }
}
.work__title {
  margin-bottom: 15px;
  letter-spacing: 0.2px;
}
@media (max-width: 1439px) {
  .work__title {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  .work__title {
    font-size: 17px;
    margin-bottom: 7px;
  }
}
@media (max-width: 1439px) {
  .work__title-h2 {
    font-size: 30px;
  }
}
.work__diameter {
  background: url(../img/diametr.svg) no-repeat top left;
  padding-left: 56px;
  background-size: 39px 28px;
  padding-top: 1px;
  min-height: 28px;
}
@media (max-width: 991px) {
  .work__diameter {
    background-size: 23px 16px;
    font-size: 12px;
    padding-left: 33px;
    background-position: 0 1px;
  }
}

@media (max-width: 991px) {
  .section--advantages-form {
    padding-bottom: 0;
  }
}

.advantages {
  margin-bottom: 50px;
}
@media (min-width: 1200px) {
  .advantages {
    margin-bottom: 0;
  }
}
@media (max-width: 991px) {
  .advantages {
    margin-bottom: 97px;
  }
}
.advantages__item {
  margin-top: 93px;
}
@media (max-width: 991px) {
  .advantages__item {
    margin-top: 76px;
  }
  .advantages__item:first-of-type {
    margin-top: 56px;
  }
}
.advantages__block {
  border-top: 1px solid #4b4b4b;
  padding-top: 31px;
}
@media (max-width: 991px) {
  .advantages__block {
    padding-top: 22px;
  }
}
.advantages__title {
  line-height: 1.28;
  letter-spacing: 0.2px;
  margin-bottom: 22px;
  min-height: 64px;
  padding-right: 30px;
}
@media (max-width: 991px) {
  .advantages__title {
    font-size: 20px;
    padding-right: 0;
    min-height: auto;
    margin-bottom: 17px;
  }
}
.advantages__desc {
  font-size: 16px;
  line-height: 1.5;
}
@media (max-width: 991px) {
  .advantages__desc {
    font-size: 15px;
    line-height: 1.6;
  }
}

/*form*/
.form__wrapper {
  margin-left: 32px;
  padding: 75px 79px 103px;
  position: relative;
  z-index: 1;
  background-color: var(--color-tundora);
  border-radius: 20px;
}

.form__content {
  color: var(--color-white);
  margin-left: auto;
  position: relative;
  z-index: 1;
}

.form__picture {
  position: absolute;
  right: 444px;
  top: -98px;
  z-index: -1;
  max-width: none;
}

.form__header {
  margin-bottom: 72px;
}

.form__title {
  margin-bottom: 14px;
}

.form__group {
  margin-bottom: 26px;
}

.form__group--btn {
  margin-bottom: 50px;
  padding-top: 8px;
}

.form__group-wrapper {
  position: relative;
  z-index: 1;
}

.form__icon {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: 100%;
  width: 66px;
}

.error {
  color: red;
}

.form__control {
  background-color: var(--color-white);
  border: none;
  color: #939393;
  font-size: 17px;
  font-family: inherit;
  letter-spacing: 0.2px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  border-radius: 5px;
}

.form__icon + .form__control-input {
  padding-left: 59px;
}

.form__icon-picture--1 {
  width: 23px;
}

.form__icon-picture--2 {
  width: 15px;
}

.form__control-input {
  height: 77px;
}

.form__control-textarea {
  resize: none;
  overflow: auto;
  min-height: 174px;
  padding-top: 18px;
  padding-bottom: 18px;
}

.agree {
  padding-left: 45px;
  position: relative;
  font-size: 14px;
  color: #777;
}

.agree__label {
  position: absolute;
  left: 0;
  top: -6px;
  z-index: 1;
}

.agree__checkbox {
  display: none;
}

.agree__icon {
  height: 25px;
  width: 25px;
  cursor: pointer;
  display: block;
  background-color: #707070;
  border-radius: 6px;
}

.agree__checked {
  opacity: 0;
  transition: 0.3s;
}

.agree__checkbox:checked ~ .agree__icon .agree__checked {
  opacity: 1;
}

.agree__link {
  color: inherit;
  transition: 0.3s;
}

.agree__link:hover {
  color: var(--color-white);
}

@media (max-width: 991px) {
  .form__wrapper {
    padding: 46px 15px 61px;
    margin: 0 -15px;
    border-radius: 0;
  }

  .form__header {
    text-align: center;
    margin-bottom: 32px;
  }

  .form__title {
    margin-bottom: 8px;
  }

  .form__subtitle {
    font-size: 17px;
    line-height: 1.4118;
  }

  .form__group {
    margin-bottom: 17px;
    padding-top: 0;
  }

  .form__group--btn {
    margin-bottom: 25px;
  }

  .form__control-input {
    height: 62px;
  }

  .form__icon {
    width: 56px;
  }

  .form__icon + .form__control-input {
    padding-left: 49px;
  }

  .agree__label {
    top: 1px;
  }
}
.section--contacts {
  padding-top: 168px;
  color: var(--color-dark);
}
@media (max-width: 991px) {
  .section--contacts {
    padding-top: 0;
    padding-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .map {
    height: 355px;
    margin: 0 -15px 25px;
    display: block;
    position: relative;
    z-index: 1;
  }
  .map iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border: none;
    width: 100%;
    z-index: 1;
  }
}

.contacts {
  max-width: 600px;
  min-height: 700px;
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .contacts {
    margin-left: -8px;
    margin-right: -8px;
    min-height: auto;
    padding-top: 5px;
  }
}
.contacts__item {
  margin-bottom: 50px;
  min-width: 254px;
}
@media (max-width: 991px) {
  .contacts__item {
    min-width: auto;
    padding-left: 8px;
    padding-right: 8px;
    max-width: 50%;
    flex: 1 1 50%;
    margin-bottom: 2px;
    min-height: 37px;
  }
}
.contacts__text {
  display: block;
  position: relative;
  z-index: 1;
  font-size: 21px;
  font-family: "HelveticaNeueCyr", sans-serif;
  font-weight: 300;
  padding-left: 50px;
}
@media (max-width: 991px) {
  .contacts__text {
    font-size: 14px;
    line-height: 1.2143;
    padding-left: 24px;
  }
}
.contacts__text::before {
  content: "";
  height: 30px;
  width: 31px;
  background: url(../img/arrow-right-bottom.svg) no-repeat top left;
  position: absolute;
  left: 0;
  top: -4px;
  z-index: 1;
}
@media (max-width: 991px) {
  .contacts__text::before {
    background-size: 15px 15px;
    top: -1px;
  }
}
@media (min-width: 992px) {
  .contacts__map {
    background: url("../img/map.jpg") no-repeat top left;
    height: 741px;
    width: 1378px;
    position: absolute;
    left: 521px;
    top: -172px;
    z-index: -1;
  }
}
.contacts__pin {
  display: none;
}
@media (min-width: 992px) {
  .contacts__pin {
    position: absolute;
    z-index: 1;
    display: block;
  }
  .contacts__pin--1 {
    left: 90px;
    top: 623px;
  }
  .contacts__pin--2 {
    left: 140px;
    top: 638px;
  }
  .contacts__pin--3 {
    left: 172px;
    top: 653px;
  }
  .contacts__pin--4 {
    left: 90px;
    top: 623px;
  }
  .contacts__pin--5 {
    left: 204px;
    top: 597px;
  }
  .contacts__pin--6 {
    left: 231px;
    top: 634px;
  }
  .contacts__pin--7 {
    left: 183px;
    top: 424px;
  }
  .contacts__pin--8 {
    left: 261px;
    top: 494px;
  }
  .contacts__pin--9 {
    left: 217px;
    top: 496px;
  }
  .contacts__pin--10 {
    left: 307px;
    top: 597px;
  }
  .contacts__pin--11 {
    left: 730px;
    top: 563px;
  }
}

.section--stages {
  color: var(--color-white);
  padding: 132px 0 202px;
}
@media (max-width: 991px) {
  .section--stages {
    padding-top: 48px;
    padding-bottom: 50px;
  }
}

.swiper-slide--stages {
  padding-left: 7.5px;
  padding-right: 7.5px;
}
@media (min-width: 992px) {
  .swiper-slide--stages::before {
    content: "";
    height: 17px;
    width: 59px;
    background: url(../img/arrow-right.png) no-repeat top left;
    position: absolute;
    right: -24px;
    top: 31px;
    z-index: 50;
  }
  .swiper-slide--stages:last-of-type::before {
    display: none;
  }
}
@media (max-width: 991px) {
  .swiper-slide--stages {
    width: 305px;
  }
}

.stages {
  margin-left: -7.5px;
  margin-right: -7.5px;
}
@media (min-width: 992px) {
  .stages__dots {
    margin-top: 30px;
  }
}

.stage {
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 36px 18px 34px;
  color: #0c0c0c;
  position: relative;
  z-index: 1;
}
.stage::before {
  content: attr(data-count);
  color: var(--color-wild-sand);
  font-size: 66px;
  font-family: "HelveticaNeueCyr", sans-serif;
  font-weight: 300;
  position: absolute;
  bottom: 11px;
  right: 23px;
  z-index: -1;
}
.stage__icon {
  height: 72px;
  width: 72px;
  border-radius: 50%;
  background-color: var(--color-tundora);
  color: var(--color-white);
  margin-bottom: 29px;
}
.stage__text {
  font-size: 15px;
  line-height: 1.467;
}

.icon__svg--step-1 {
  height: 24px;
  width: 24px;
}
.icon__svg--step-2 {
  height: 24px;
  width: 25px;
}
.icon__svg--step-3 {
  height: 29px;
  width: 30px;
}
.icon__svg--step-4 {
  height: 30px;
  width: 19px;
}
.icon__svg--step-5 {
  height: 28px;
  width: 24px;
}

.page__row--content {
  padding-top: 57px;
}

.main-title {
  margin-bottom: 28px;
}
@media (max-width: 991px) {
  .main-title {
    font-size: 31px;
    line-height: 1.1936;
    letter-spacing: 0.2px;
    margin-bottom: 22px;
  }
}

.gallery {
  margin-bottom: 63px;
}
@media (max-width: 991px) {
  .gallery {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (min-width: 992px) {
  .gallery__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 23px;
    grid-row-gap: 23px;
  }
  .gallery__wrapper .swiper-slide--gallery:nth-of-type(1) {
    grid-area: 1/1/3/3;
  }
  .gallery__wrapper .swiper-slide--gallery:nth-of-type(2) {
    grid-area: 1/3/2/4;
  }
  .gallery__wrapper .swiper-slide--gallery:nth-of-type(3) {
    grid-area: 1/4/2/5;
  }
  .gallery__wrapper .swiper-slide--gallery:nth-of-type(4) {
    grid-area: 2/3/3/4;
  }
  .gallery__wrapper .swiper-slide--gallery:nth-of-type(5) {
    grid-area: 2/4/3/5;
  }
  .gallery__wrapper .swiper-slide--gallery:nth-of-type(6) {
    grid-area: 3/1/4/2;
  }
  .gallery__wrapper .swiper-slide--gallery:nth-of-type(7) {
    grid-area: 3/2/4/3;
  }
  .gallery__wrapper .swiper-slide--gallery:nth-of-type(8) {
    grid-area: 4/1/5/2;
  }
  .gallery__wrapper .swiper-slide--gallery:nth-of-type(9) {
    grid-area: 4/2/5/3;
  }
  .gallery__wrapper .swiper-slide--gallery:nth-of-type(10) {
    grid-area: 3/3/5/5;
  }
}
@media (max-width: 991px) {
  .gallery__wrapper {
    margin-bottom: 23px;
  }
}
.gallery__link {
  display: block;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  padding-bottom: 80.5%;
}
.gallery__link::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgba(56, 56, 56, 0.9) url(../img/card-zoom-image.svg) no-repeat center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  border-radius: inherit;
  background-size: 41px 41px;
}
.gallery__link:hover::before {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 991px) {
  .gallery__link {
    border-radius: 0;
    padding-bottom: 0;
    height: 347px;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
}
.gallery__picture {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.content__button {
  margin-bottom: 27px;
  display: block;
  cursor: text;
  position: relative;
  z-index: 1;
}
.content__button-icon {
  position: absolute;
  right: 5px;
  top: 16px;
  z-index: 1;
}
@media (max-width: 991px) {
  .content__button {
    margin-bottom: 22px;
    padding-right: 40px;
  }
}
.content__button img {
  transition: 0.3s;
}
.content__button.active img {
  transform: rotate(-180deg);
}
.content__wrapper {
  margin-bottom: 67px;
}
.content__wrapper:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .content__wrapper {
    display: none;
  }
  .content__wrapper.active {
    display: block;
  }
}

@media (max-width: 991px) {
  .specifications {
    font-size: 17px;
  }
}
.specifications__flex {
  border-top: 1px solid #f7f7f7;
  padding: 11px 0 8px;
}
.specifications__flex:first-of-type {
  padding-top: 8px;
}
.specifications__flex:last-of-type {
  border-bottom: 1px solid #f7f7f7;
}
@media (max-width: 991px) {
  .specifications__flex {
    padding: 7px 0 9px;
  }
}
.specifications__block:first-of-type {
  min-width: 180px;
}
@media (min-width: 1200px) {
  .specifications__block:first-of-type {
    min-width: 246px;
  }
}
@media (max-width: 991px) {
  .specifications__block:first-of-type {
    min-width: 100%;
    color: #7c7c7c;
    margin-bottom: 7px;
  }
}
@media (max-width: 991px) {
  .specifications__block:last-of-type {
    color: var(--color-dark);
  }
}

@media (min-width: 992px) {
  .description {
    padding-top: 24px;
  }
}
@media (min-width: 992px) {
  .description p:not(.txt) {
    line-height: 1.7647;
    margin-bottom: 31px;
  }
}

@media (min-width: 992px) {
  .txt {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 19px;
  }
}
.top {
  position: relative;
  z-index: 1;
  color: var(--color-white);
}
@media (min-width: 992px) {
  .top {
    min-height: 535px;
    height: calc(100vh - var(--header-height));
    max-height: 662px;
    padding: 40px 0;
  }
}
@media (min-width: 1440px) {
  .top {
    padding: 60px 0 0;
  }
}
@media (min-width: 992px) {
  .top__bg {
    position: absolute;
    left: calc(50% - 10px);
    top: 0;
    height: 100%;
    width: 50vw;
    z-index: 100;
  }
}
@media (max-width: 991px) {
  .top__bg {
    padding-bottom: 88%;
    margin: 0 -15px;
  }
}
@media (min-width: 992px) {
  .top__content {
    padding-right: 50px;
    max-width: 50%;
  }
}
@media (min-width: 1440px) {
  .top__content {
    min-height: 432px;
  }
}
@media (max-width: 991px) {
  .top__content {
    margin: 0 -15px;
    background-color: #4b4b4b;
    overflow: hidden;
    padding: 19px 0;
  }
}
.top__title {
  margin: 18px 0 51px;
}
@media (max-width: 991px) {
  .top__title {
    margin-bottom: 38px;
  }
}
.top__btn {
  margin-bottom: 50px;
}

.scroll-down {
  font-size: 10px;
  color: #eaeaea;
  padding-left: 37px;
  position: absolute;
  bottom: 62px;
  left: 0;
  z-index: 1;
  min-height: 30px;
  letter-spacing: 1.6px;
}
@media (min-width: 1440px) {
  .scroll-down {
    bottom: 112px;
  }
}
.scroll-down::before {
  content: "";
  height: 30px;
  width: 18px;
  background: url(../img/category-scroll_down.svg) no-repeat top left;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.section--text {
  padding: 78px 0 70px;
}
@media (max-width: 991px) {
  .section--text {
    padding-top: 48px;
    padding-bottom: 30px;
  }
}

.page__row--footer {
  background-color: var(--color-shark);
  color: var(--color-white);
}

.social__link {
  display: block;
}
.social__text {
  padding-bottom: 4px;
}
.social__picture.telegram {
  height: 28px;
  width: 28px;
}
.social__picture.viber {
  width: 28px;
}
.social__picture.inst {
  height: 26px;
  width: 26px;
}
.social__picture.fb {
  height: 26px;
  width: 26px;
}

@media (max-width: 991px) {
  .footer__row--social {
    margin-left: -8px;
    margin-right: -8px;
  }
}
@media (max-width: 991px) {
  .footer__cell--social {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.footer__top {
  padding: 57px 0 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
}
.footer__middle {
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
}
@media (max-width: 991px) {
  .footer__middle {
    padding-bottom: 20px;
  }
}
.footer__bottom {
  font-size: 14px;
  padding: 24px 0 33px;
}
@media (max-width: 991px) {
  .footer__bottom {
    color: #79797a;
    line-height: 1.1429;
    padding-bottom: 42px;
  }
}
.footer-logo {
  margin-bottom: 29px;
  text-align: left;
}
@media (min-width: 992px) {
  .footer__cell--contacts {
    margin-right: 116px;
  }
}
.footer__cell--contacts:last-of-type {
  margin-right: 0;
}
@media (max-width: 991px) {
  .footer__nav-menu {
    display: none;
  }
  .footer__nav-menu.active {
    display: block;
  }
}
.footer__nav-link {
  color: var(--color-white);
  display: block;
  padding: 29px 12px 32px;
  font-size: 16px;
  transition: all 0.3s;
}
.footer__nav-link:hover {
  opacity: 0.4;
}
@media (max-width: 991px) {
  .footer__nav-link {
    padding: 10px 15px 7px;
    margin: 0 -15px;
  }
}
.footer__nav-btn {
  font-size: 16px;
  color: #787878;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  padding: 33px 15px 18px;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  z-index: 1;
  width: calc(100% + 30px);
  text-align: left;
}
.footer__nav-icon {
  position: absolute;
  right: 15px;
  z-index: 1;
  top: 40px;
}
@media (max-width: 1199px) {
  .footer__copy {
    margin-bottom: 23px;
  }
}
@media (max-width: 991px) {
  .footer__copy {
    margin-bottom: 13px;
  }
}
.footer__separator {
  margin: 0 3px;
}
@media (min-width: 992px) {
  .footer__privacy-policy {
    margin-right: 82px;
  }
}
@media (max-width: 991px) {
  .footer__privacy-policy {
    margin-bottom: 42px;
  }
}
.footer__privacy-policy-link {
  color: var(--color-white);
}
@media (max-width: 991px) {
  .footer__privacy-policy-link {
    color: inherit;
  }
}

.footer__contacts-block, .nav__contacts-block {
  margin-bottom: 17px;
}
@media (max-width: 991px) {
  .footer__contacts-block, .nav__contacts-block {
    margin-bottom: 19px;
  }
}
.footer__contacts-icon, .nav__contacts-icon {
  color: inherit;
  background-repeat: no-repeat;
  width: 20px;
  min-height: 22px;
  margin-right: 10px;
  position: relative;
  z-index: 1;
}
.footer__contacts-icon.tel, .nav__contacts-icon.tel {
  background-image: url(../img/footer-tel-number.svg);
  background-size: 13px 21px;
}
@media (min-width: 992px) {
  .footer__contacts-icon.tel, .nav__contacts-icon.tel {
    top: 2px;
  }
}
.footer__contacts-icon.email, .nav__contacts-icon.email {
  background-image: url(../img/footer-email.svg);
  background-size: 20px 20px;
  top: 1px;
  left: -1px;
}
@media (max-width: 991px) {
  .footer__contacts-icon.email, .nav__contacts-icon.email {
    top: -1px;
    left: -3px;
  }
}
.footer__contacts-icon.pin, .nav__contacts-icon.pin {
  background-image: url(../img/footer-pin.svg);
  background-size: 17px 22px;
  top: 1px;
}
@media (max-width: 991px) {
  .footer__contacts-icon.pin, .nav__contacts-icon.pin {
    top: -1px;
  }
}
.footer__contacts-text, .nav__contacts-text {
  color: var(--color-dark);
  font-size: 15px;
}

.footer__contacts-text {
  font-size: 17px;
  color: var(--color-white);
}