.section--contacts {
    padding-top: 168px;
    color: var(--color-dark);

    @include lg-max {
        padding-top: 0;
        padding-bottom: 30px;
    }
}

.map {
    @include lg-max {
        height: 355px;
        margin: 0 -15px 25px;
        display: block;
        position: relative;
        z-index: 1;

        iframe {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            border: none;
            width: 100%;
            z-index: 1;
        }
    }
}

.contacts {
    max-width: 600px;
    min-height: 700px;
    position: relative;
    z-index: 1;

    @include lg-max {
        margin-left: -8px;
        margin-right: -8px;
        min-height: auto;
        padding-top: 5px;
    }
    
    &__item {
        margin-bottom: 50px;
        min-width: 254px;

        @include lg-max {
            min-width: auto;
            padding-left: 8px;
            padding-right: 8px;
            max-width: 50%;
            flex: 1 1 50%;
            margin-bottom: 2px;
            min-height: 37px;
        }
    }

    &__text {
        display: block;
        position: relative;
        z-index: 1;
        font-size: 21px;
        font-family: "HelveticaNeueCyr", sans-serif;
        font-weight: 300;
        padding-left: 50px;

        @include lg-max {
            font-size: 14px;
            line-height: 1.2143;
            padding-left: 24px;
        }

        &::before {
            content: '';
            height: 30px;
            width: 31px;
            background: url(../img/arrow-right-bottom.svg) no-repeat top left;
            position: absolute;
            left: 0;
            top: -4px;
            z-index: 1;

            @include lg-max {
                background-size: 15px 15px;
                top: -1px;
            }
        }
    }

    &__map {
        @include lg-min {
            background: url("../img/map.jpg") no-repeat top left;
            height: 741px;
            width: 1378px;
            position: absolute;
            left: 521px;
            top: -172px;
            z-index: -1;
        }
    }

    &__pin {
        display: none;
        @include lg-min {
            position: absolute;
            z-index: 1;
            display: block;

            &--1 {
                left: 90px;
                top: 623px;
            }

            &--2 {
                left: 140px;
                top: 638px;
            }

            &--3 {
                left: 172px;
                top: 653px;
            }

            &--4 {
                left: 90px;
                top: 623px;
            }

            &--5 {
                left: 204px;
                top: 597px
            }

            &--6 {
                left: 231px;
                top: 634px;
            }

            &--7 {
                left: 183px;
                top: 424px;
            }

            &--8 {
                left: 261px;
                top: 494px;
            }

            &--9 {
                left: 217px;
                top: 496px;
            }

            &--10 {
                left: 307px;
                top: 597px;
            }

            &--11 {
                left: 730px;
                top: 563px;
            }
        }
    }
}