.home__dots.dots {
    position: absolute;
    top: 300px;
    left: auto;
    bottom: auto;
    width: 20px;
    z-index: 100;

    @include lg-min {
        right: calc(50% - 430px);
    }

    @include xl-min {
        right: calc(50% - 550px);
    }

    @include xxl-min {
        right: calc(50% - 645px);
    }

    @include lg-max {
        width: auto;
        bottom: 47px;
        left: 50%;
        transform: translateX(-50%);
        top: auto;
    }
}

.swiper-slide {
    height: auto;
}

.dots {
    @include lg-max {
        padding-top: 27px;
    }

    .swiper-pagination-bullet {
        height: 13px;
        width: 13px;
        margin: 0;
        padding: 4px;
        background-clip: content-box;
        background-color: var(--color-white);
        opacity: .4;
        transition: all .3s;

        @include lg-max {
            margin: 0 1px!important;
        }

        &-active {
            opacity: 1;
            transform: scale(1.5);
        }
    }

    &--gray {
        .swiper-pagination-bullet {
            background-color: #d2d2d2;
        }
    }
}

.carousel-nav {
    margin-right: -20px;
}

.prev, .next {
    color: #0f0f0f;
    padding: 10px;
    cursor: pointer;
    margin: 0 10px;
}

.prev__icon {
    transform: rotate(180deg);
}

.prev__icon, .next__icon {
    height: 45px;
    width: 41px;
}

.swiper-overflow-visible {
    overflow: visible;
}