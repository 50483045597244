.home {
    color: var(--color-white);

    .swiper-slide {
        height: 100%;
    }

    &__item {
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            z-index: -1;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(to bottom, rgba(12, 12, 12, 0.71) 0%, rgba(12, 12, 12, 0.6) 1%, rgba(13, 13, 13, 0.38) 94%, rgba(11, 11, 11, 0.36) 100%);
        }
    }

    &__wrapper {
        height: 100vh;
        min-height: 600px;
        max-height: 796px;
        padding-bottom: 109px;

        @include lg-max {
            padding-top: 150px;
            min-height: 480px;
        }
    }

    &__content {
        max-width: 766px;

        @include lg-max {
            text-align: center;
        }
    }

    &__title {
        margin-top: 18px;
        margin-bottom: 34px;

        @include lg-max {
            margin-top: 12px;
        }
    }

    &__text {
        font-size: 13px;
        display: block;
        letter-spacing: 1.2px;
    }
}

.disc-num.swiper-pagination-bullets {
    @include lg-min {
        right: calc(50% - 450px);
    }

    @include xl-min {
        right: calc(50% - 550px);
    }

    @include xxl-min {
        right: calc(50% - 665px);
    }
}

.disc-num.swiper-pagination-bullets {counter-reset: slides-num; color: #fff; position: absolute; bottom: 78px; left: auto; z-index: 50; text-align: left; padding-left: 50px; width: auto; font-size: 18px;}
.disc-num::before, .disc-num::after {display: inline-block; vertical-align: middle; line-height: 1; position: relative; top: -3px;}
.disc-num::before {content: '/'; color: inherit;}
.disc-num::after {content: counter(slides-num, decimal-leading-zero); font-size: 14px;margin-left: 3px; letter-spacing: 1px;}
.disc-num .swiper-pagination-bullet {display: inline-block; cursor: default; counter-increment: slides-num; margin-right: 5px; position: absolute; left: 0; top: 0; background: none;}
.disc-num .swiper-pagination-bullet::after {content: counter(slides-num, decimal-leading-zero); font-size: 18px; line-height: 1; text-align: right; position: absolute; right: -37px; top: 0; opacity: 0; z-index: 50; letter-spacing: 1px;}
.disc-num .swiper-pagination-bullet-active::after {opacity: 1; z-index: 100;}
.disc-num .swiper-pagination-bullet::before {display: none;}