.top {
    position: relative;
    z-index: 1;
    color: var(--color-white);
    
    @include lg-min {
        min-height: 535px;
        height: calc(100vh - var(--header-height));
        max-height: 662px;
        padding: 40px 0;
    }

    @include xxl-min {
        padding: 60px 0 0;
    }

    &__bg {
        @include lg-min {
            position: absolute;
            left: calc(50% - 10px);
            top: 0;
            height: 100%;
            width: 50vw;
            z-index: 100;
        }
        
        @include lg-max {
            padding-bottom: 88%;
            margin: 0 -15px;
        }
    }

    &__content {
        @include lg-min {
            padding-right: 50px;
            max-width: 50%;
        }

        @include xxl-min {
            min-height: 432px;
        }

        @include lg-max {
            margin: 0 -15px;
            background-color: #4b4b4b;
            overflow: hidden;
            padding: 19px 0;
        }
    }

    &__title {
        margin: 18px 0 51px;

        @include lg-max {
            margin-bottom: 38px;
        }
    }

    &__btn {
        margin-bottom: 50px;
    }
}

.scroll-down {
    font-size: 10px;
    color: #eaeaea;
    padding-left: 37px;
    position: absolute;
    bottom: 62px;
    left: 0;
    z-index: 1;
    min-height: 30px;
    letter-spacing: 1.6px;

    @include xxl-min {
        bottom: 112px;
    }

    &::before {
        content: '';
        height: 30px;
        width: 18px;
        background: url(../img/category-scroll_down.svg) no-repeat top left;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
    }
}