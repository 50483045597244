/*form*/
.form__wrapper {margin-left: 32px; padding: 75px 79px 103px; position: relative; z-index: 1; background-color: var(--color-tundora); border-radius: 20px;}
.form__content {color: var(--color-white); margin-left: auto; position: relative; z-index: 1;}
.form__picture {position: absolute; right: 444px; top: -98px; z-index: -1; max-width: none;}
.form__header {margin-bottom: 72px;}
.form__title {margin-bottom: 14px;}
.form__group {margin-bottom: 26px;}
.form__group--btn {margin-bottom: 50px; padding-top: 8px;}
.form__group-wrapper {position: relative; z-index: 1;}
.form__icon {position: absolute; left: 0; top: 0; z-index: 1; height: 100%; width: 66px;}
.error  {color: red;}
.form__control {background-color: var(--color-white); border: none; color: #939393; font-size: 17px; font-family: inherit; letter-spacing: .2px; padding-left: 20px; padding-right: 20px; width: 100%; border-radius: 5px;}
.form__icon + .form__control-input {padding-left: 59px;}
.form__icon-picture--1 {width: 23px;}
.form__icon-picture--2 {width: 15px;}
.form__control-input {height: 77px;}
.form__control-textarea {resize: none; overflow: auto; min-height: 174px; padding-top: 18px; padding-bottom: 18px;}
.agree {padding-left: 45px; position: relative; font-size: 14px; color: #777;}
.agree__label {position: absolute; left: 0; top: -6px; z-index: 1;}
.agree__checkbox {display: none;}
.agree__icon {height: 25px; width: 25px; cursor: pointer; display: block; background-color: #707070; border-radius: 6px;}
.agree__checked {opacity: 0; transition: .3s;}
.agree__checkbox:checked ~ .agree__icon .agree__checked {opacity: 1;}
.agree__link {color: inherit; transition: .3s;}
.agree__link:hover {color: var(--color-white);}

@include lg-max {
    .form__wrapper {
        padding: 46px 15px 61px;
        margin: 0 -15px;
        border-radius: 0;
    }

    .form__header {
        text-align: center;
        margin-bottom: 32px;
    }

    .form__title {
        margin-bottom: 8px;
    }

    .form__subtitle {
        font-size: 17px;
        line-height: 1.4118;
    }

    .form__group {
        margin-bottom: 17px;
        padding-top: 0;
    }

    .form__group--btn {
        margin-bottom: 25px;
    }

    .form__control-input {
        height: 62px;
    }

    .form__icon {
        width: 56px;
    }

    .form__icon + .form__control-input {
        padding-left: 49px;
    }

    .agree__label {
        top: 1px;
    }
}