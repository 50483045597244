.section--works {
    @include lg-max {
        padding-top: 42px;
    }
}

.works {
    @include lg-max {
        &__dots {
            padding-bottom: 30px;
        }
    }
}

.work {
    background-color: var(--color-mine-shaft-2);
    background-repeat: no-repeat;
    color: var(--color-white);
    
    &::before {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        background: url(../img/work-overlay.png) no-repeat top left;
        background-size: cover;
    }

    &__picture {
        z-index: -2;
    }

    &__btn {
        @include xxl-max {
            min-width: 100%;
        }
    }

    &__link {
        width: 100%;
        display: block;
        padding-bottom: 139%;
    }

    &__content {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 10;
    }

    &__content, &__wrapper {
        padding: 72px 46px 79px;
    }

    &__content {
        padding-top: 0;
        padding-bottom: 0;
        bottom: 79px;

        @include lg-max {
            padding-left: 29px;
            padding-right: 29px;
            bottom: 33px;
        }
    }

    &__text {
        margin-bottom: 12px;
        padding-left: 56px;
        position: relative;
        z-index: 1;
        color: var(--color-nobel);
        font-size: 15px;

        &::before {
            content: '';
            width: 40px;
            height: 1px;
            background-color: #a9a9a9;
            position: absolute;
            left: 0;
            top: 9px;
            z-index: 1;

            @include lg-max {
                width: 23px;
                top: 7px;
            }
        }

        @include lg-max {
            font-size: 12px;
            padding-left: 35px;
            margin-bottom: 5px;
        }
    }

    &__title {
        margin-bottom: 15px;
        letter-spacing: .2px;

        @include xxl-max {
            font-size: 18px;
        }

        @include lg-max {
            font-size: 17px;
            margin-bottom: 7px;
        }
    }

    &__title-h2 {
        @include xxl-max {
            font-size: 30px;
        }
    }

    &__diameter {
        background: url(../img/diametr.svg) no-repeat top left;
        padding-left: 56px;
        background-size: 39px 28px;
        padding-top: 1px;
        min-height: 28px;

        @include lg-max {
            background-size: 23px 16px;
            font-size: 12px;
            padding-left: 33px;
            background-position: 0 1px;
        }
    }
}