.page__row--header {
    background-color: var(--color-mine-shaft);
    height: var(--header-height);
    
    &-abs {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1000;
        background-color: transparent;
        border-bottom: 1px solid rgba(255, 255, 255, .15);
    }

    @include lg-max {
        height: auto;
    }
}

.header {
    padding: 28px 0 40px;

    @include lg-max {
        padding: 0;
    }

    &__row {
        @include lg-max {
            margin-left: -5px;
            height: var(--header-height);
            margin-right: -5px;
        }
    }

    &__cell {
        @include lg-max {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    &__cell--logo {
        margin-right: 80px;

        @include xxl-max {
            margin-right: 40px;
        }

        @include lg-max {
            margin-right: 0;
        }
    }

    &__cell--nav {
        @include lg-max {
            position: absolute;
            z-index: 1000;
        }
    }
}

.logo, .footer-logo {
    color: var(--color-white);
    position: relative;
    z-index: 1;

    &--nav {
        color: inherit;
    }

    &__picture {
        margin-right: 22px;
        position: relative;
        top: -1px;
        width: 77px;
    }

    &__title {
        display: block;
        font-size: 18px;
        font-family: 'HelveticaNeueCyr', sans-serif;
        font-weight: 700;
        letter-spacing: .2px;
        margin-bottom: 3px;
    }

    &__desc {
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: .2px;
    }
}

.logo {
    @include lg-max {
        width: 170px;
    }

    &__picture {
        @include lg-max {
            width: 66px;
            margin-right: 8px;
        }

        &--nav {
            width: 100px;
            margin-right: 16px;
        }
    }

    &__title {
        @include lg-max {
            line-height: 1.2;
            font-size: 15px;
        }

        &--nav {
            font-size: 16px;
            margin-bottom: 0;
            padding-top: 4px;
        }
    }
}

.nav {
    color: var(--color-white);
    padding-top: 11px;

    @include xxl-max {
        font-size: 14px;
        padding: 0 15px;
    }

    @include lg-max {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        background-color: #fafafa;
        color: var(--color-dark);
        height: 100vh;
        max-height: 100%;
        overflow: auto;
        margin: 0;
        padding: 0 0 30px;
        max-width: 252px;
        width: 100%;
        -ms-transform: translate3d(-100%,0,0);
        -webkit-transform: translate3d(-100%,0,0);
        transform: translate3d(-100%,0,0);
        -ms-transition: left .5s ease;
        -webkit-transition: left .6s cubic-bezier(.4,.2,0,1);
        transition: left .6s cubic-bezier(.4,.2,0,1);

        &.active {
            left: 252px;
        }
    }

    &__menu {
        @include lg-max {
            border-top: 1px solid #f0f0f0;
            border-bottom: 1px solid #f0f0f0;
            margin: 0 -15px;
        }
    }

    &__item {
        margin-right: 47px;

        @include xxl-max {
            margin-right: 30px;
        }

        @include lg-max {
            margin-right: 0;
            border-bottom: 1px solid #f0f0f0;

            &:last-of-type {
                border-bottom: none;
            }
        }

        &:last-of-type {margin-right: 0;}
    }
    
    &__link {
        color: inherit;
        transition: all .2s;

        &:hover {
            color: rgba(255, 255, 255, .85);
        }

        @include lg-max {
            font-size: 17px;
            padding: 15px 15px 16px;
            display: block;

            &:hover {
                color: inherit;
            }
        }
    }

    &__top {
        @include lg-max {
            background-color: var(--color-white);
            padding-top: 32px;
            margin-bottom: 60px;
        }
    }

    &__logo {
        margin-bottom: 39px;
    }

    &__tel {
        color: inherit;

        @include xxl-max {
            font-size: 14px;
        }
    }

    &__btn {

        @include xxl-max {
            min-width: auto;
        }
    }

    &__contacts {

        &-block {
            margin-bottom: 22px;
        }

        &-icon {
            min-height: 20px;

            &.tel {
                background-position: 1px 0;
                background-size: 13px 21px;
                font-size: 15px;
            }

            &.email {
                background-size: 20px 20px;
            }

            &.pin {
                background-size: 17px 22px;
            }
        }
    }

    &__overlay {
        @include lg-max {
            background-color: rgba(0, 0, 0, .62);
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            transition: z-index .3s linear, opacity .3s linear, visibility .3s linear;

            &.active {
                z-index: 500;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.btn-tel {
    height: 38px;
    width: 38px;
    border: 1px solid rgba(255, 255, 255, .27);
    border-radius: 50%;
    position: relative;
    top: -3px;

    &__icon {
        width: 15px;
    }

    margin-right: 13px;
}

.hamburger {
    padding: 13px;
    margin-right: -13px;
}