.page__row--content {
    padding-top: 57px;
}

.main-title {
    margin-bottom: 28px;

    @include lg-max {
        font-size: 31px;
        line-height: 1.1936;
        letter-spacing: .2px;
        margin-bottom: 22px;
    }
}

.gallery {
    margin-bottom: 63px;

    @include lg-max {
        margin-left: -15px;
        margin-right: -15px;
    }

    &__wrapper {
        @include lg-min {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(4, 1fr);
            grid-column-gap: 23px;
            grid-row-gap: 23px;
                
            .swiper-slide--gallery:nth-of-type(1) { grid-area: 1 / 1 / 3 / 3; }
            .swiper-slide--gallery:nth-of-type(2) { grid-area: 1 / 3 / 2 / 4; }
            .swiper-slide--gallery:nth-of-type(3) { grid-area: 1 / 4 / 2 / 5; }
            .swiper-slide--gallery:nth-of-type(4) { grid-area: 2 / 3 / 3 / 4; }
            .swiper-slide--gallery:nth-of-type(5) { grid-area: 2 / 4 / 3 / 5; }
            .swiper-slide--gallery:nth-of-type(6) { grid-area: 3 / 1 / 4 / 2; }
            .swiper-slide--gallery:nth-of-type(7) { grid-area: 3 / 2 / 4 / 3; }
            .swiper-slide--gallery:nth-of-type(8) { grid-area: 4 / 1 / 5 / 2; }
            .swiper-slide--gallery:nth-of-type(9) { grid-area: 4 / 2 / 5 / 3; }
            .swiper-slide--gallery:nth-of-type(10) { grid-area: 3 / 3 / 5 / 5; }
        }

        @include lg-max {
            margin-bottom: 23px;
        }
    }

    &__link {
        display: block;
        overflow: hidden;
        border-radius: 5px;
        position: relative;
        z-index: 1;
        padding-bottom: 80.5%;

        &::before {
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
            background: rgba(56, 56, 56, .9) url(../img/card-zoom-image.svg) no-repeat center;
            opacity: 0;
            visibility: hidden;
            transition: all .2s;
            border-radius: inherit;
            background-size: 41px 41px;
        }
        &:hover::before {
            opacity: 1;
            visibility: visible;
        }

        @include lg-max {
            border-radius: 0;
            padding-bottom: 0;
            height: 347px;
            max-width: 320px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__picture {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    }
}

.content {
    &__button {
        margin-bottom: 27px;
        display: block;
        cursor: text;
        position: relative;
        z-index: 1;

        &-icon {
            position: absolute;
            right: 5px;
            top: 16px;
            z-index: 1;
        }

        @include lg-max {
            margin-bottom: 22px;
            padding-right: 40px;
        }

        img {
            transition: .3s;
        }

        &.active {
            img {
                transform: rotate(-180deg);
            }
        }
    }

    &__wrapper {
        margin-bottom: 67px;

        &:last-of-type {
            margin-bottom: 0;
        }

        @include lg-max {
            display: none;

            &.active {
                display: block;
            }
        }
    }
}

.specifications {
    @include lg-max {
        font-size: 17px;
    }

    &__flex {
        border-top: 1px solid #f7f7f7;
        padding: 11px 0 8px;

        &:first-of-type {
            padding-top: 8px;
        }

        &:last-of-type {
            border-bottom: 1px solid #f7f7f7;
        }

        @include lg-max {
            padding: 7px 0 9px;
        }
    }

    &__block {
        
        &:first-of-type {
            min-width: 180px;

            @include xl-min {
                min-width: 246px;
            }

            @include lg-max {
                min-width: 100%;
                color: #7c7c7c;
                margin-bottom: 7px;
            }
        }

        &:last-of-type {
            @include lg-max {
                color: var(--color-dark);
            }
        }
    }
}

.description {
    @include lg-min {
        padding-top: 24px;
    }

    @include lg-min {
        p:not(.txt) {
            line-height: 1.7647;
            margin-bottom: 31px;
        }
    }
}

@include lg-min {
    .txt {
        font-size: 20px;
        line-height: 1.6;
        margin-bottom: 19px;
    }
}