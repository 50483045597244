.section--advantages-form {
    @include lg-max {
        padding-bottom: 0;
      }
}

.advantages {
    margin-bottom: 50px;

    @include xl-min {
        margin-bottom: 0;
    }

    @include lg-max {
        margin-bottom: 97px;
    }

    &__item {
        margin-top: 93px;

        @include lg-max {
            margin-top: 76px;

            &:first-of-type {
                margin-top: 56px;
            }
        }
    }

    &__block {
        border-top: 1px solid #4b4b4b;
        padding-top: 31px;

        @include lg-max {
            padding-top: 22px;
        }
    }

    &__title {
        line-height: 1.28;
        letter-spacing: .2px;
        margin-bottom: 22px;
        min-height: 64px;
        padding-right: 30px;

        @include lg-max {
            font-size: 20px;
            padding-right: 0;
            min-height: auto;
            margin-bottom: 17px;
        }
    }

    &__desc {
        font-size: 16px;
        line-height: 1.5;

        @include lg-max {
            font-size: 15px;
            line-height: 1.6;
        }
    }
}