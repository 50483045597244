*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 17px;
  line-height: 1.4118;
  color: var(--color-tundora);
  letter-spacing: .2px;

  @include lg-max {
    font-size: 15px;
    line-height: 1.3333;
    overflow-x: hidden;
    
    &.active {
      overflow: hidden;
    }
  }
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.title {
  font-family: 'HelveticaNeueCyr', sans-serif;

  &--mrb-0 {
    margin-bottom: 0!important;
  }
  
  &--h1 {
    font-size: 60px;
    line-height: 1.2;
    letter-spacing: .6px;

    @include lg-max {
      font-size: 31px;
    }
  }
  
  &--h2 {
    font-size: 50px;
    line-height: 1.44;
    letter-spacing: .6px;

    @include lg-max {
      font-size: 28px;
      line-height: 1.5089;
      letter-spacing: .2px;

      &-mobile {
        line-height: 1.215;
      }
    }
  }
  
  &--h4 {
    font-size: 25px;
    line-height: 1.52;
    letter-spacing: .6px;

    @include lg-max {
      font-size: 17px;
      line-height: 1.3118;
    }
  }
}

.regular {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

.light {
  font-weight: 300;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left;
}

.text-left-mobile {
  @include lg-max {
    text-align: left!important;
  }
}

.text-center {
  text-align: center;
}

.text-center-mobile {
  @include lg-max {
    text-align: center;
  }
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.bg-color-alabaster {
  background-color: var(--color-alabaster);
}

.bg-color-tundora {
  background-color: var(--color-tundora);
}

.bg-color-wild-sand-mobile {
  @include lg-max {
    background-color: var(--color-wild-sand);
  }
}

.bg-overlay {
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: rgba(12, 12, 12, .65);
  }
}

.object-fit-wrapper {
  position: relative;
  z-index: 1;
  display: block;
  overflow: hidden;
}
.object-fit-cover, .object-fit-contain {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  -ms-transform: scale(1) perspective(600px);
  transform: scale(1) perspective(600px);
  -ms-transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
}
.object-fit-contain {
  object-fit: contain;
}
.object-fit-cover {
  object-fit: cover;
}
.object-fit-wrapper:hover .object-fit-cover, .object-fit-wrapper:hover .object-fit-contain {
  transform: scale(1.1) perspective(600px);
}

.color-dark {
  color: var(--color-dark);
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.icon {
  &__svg--social {
    color: var(--color-jumbo);
    height: 28px;
    width: 28px;
  }
}

.container {
  @include xxl-min {
    max-width: var(--container-width);
  }

  &-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.dis-scroll {
  position: relative;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

.page {
  height: 100vh;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

.overflow-hidden {
  overflow: hidden;
}

.user-select-none {
  user-select: none;
}

.pointer-events-none {
  pointer-events: none;
}

.height-fluid {
  height: 100%;
}

.row-mr-0 {
  margin-left: 0;
  margin-right: 0;
}

.row-mr-8 {
  margin-left: -8px;
  margin-right: -8px;
}

.row-mr-11 {
  margin-left: -11px;
  margin-right: -11px;
}

.row-mr-12 {
  margin-left: -12px;
  margin-right: -12px;
}

.row-mr-16 {
  margin-left: -16px;
  margin-right: -16px;
}

.row-mr-42 {
  margin-left: -42px;
  margin-right: -42px;
}

.col-pd-0 {
  padding-left: 0;
  padding-right: 0;
}

.col-pd-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.col-pd-11 {
  padding-left: 11px;
  padding-right: 11px;
}

.col-pd-12 {
  padding-left: 12px;
  padding-right: 12px;
}

.col-pd-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.col-pd-42 {
  padding-left: 42px;
  padding-right: 42px;
}

.text {
  letter-spacing: .2px;
  margin-bottom: 59px;
  
  &--24 {
    margin-bottom: 24px;

    @include lg-max {
      margin-bottom: 20px;
    }
  }
}

.section {
  &--default {
    padding-top: 94px;
    padding-bottom: 100px;

    @include lg-max {
      padding-top: 44px;
    }
  }

  &__header {
    padding: 42px 0 45px;
  }

  &__cell {
    &--content {
      margin-bottom: 62px;
      @include xl-min {
        padding-top: 24px;
      }

      @include lg-max {
        margin-bottom: 52px;
      }
    }

    &--advantages {
      padding-top: 75px;
      background: url("../img/advantages-arrow.png") no-repeat calc(100% - 37px) 8px;

      @include lg-max {
        background: none;
        padding-top: 0;
      }
    }

    &--text {
      @include xxl-min {
        padding-top: 28px;
      }

      @include lg-min {
        padding-bottom: 40px;
      }
    }

    &--image {
      margin-bottom: 22px;
    }
  }

  &__main-title {
    font-size: 64px;
    line-height: 1.202;
    color: var(--color-dark);
    letter-spacing: 1.4px;
    margin-bottom: 20px;

    @include xl-min {
      font-size: 104px;
      margin-bottom: 62px;
    }

    @include lg-max {
      font-size: 58px;
      margin-bottom: 16px;
    }
  }

  &__title {
    margin-bottom: 66px;

    @include lg-max {
      margin-bottom: 28px;
    }
  }

  &__picture {
    border-radius: 8px;
  }

  &__text {
    @include lg-max {
      line-height: 1.6;
      margin-bottom: 20px;
    }
  }

  &__btn-wrapper {
    padding: 63px 15px 71px;
    text-align: center;
  }
}


@include xxl-min {
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .d-xxl-block {
    display: block!important;
  }
}