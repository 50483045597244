@import "./mixins/breakpoint";

:root {
  // base

  --font-family: "Montserrat";

  --header-height: 132px;
  
  --container-width: 1360px;
  --container-offset: 15px;
  
  // colors
  --color-white: #fff;
  --color-dark: #222;
  --color-tundora: #4B4B4B;
  --color-mine-shaft: #383838;
  --color-mine-shaft-2: #3F3F3F;
  --color-wild-sand: #F4F4F4;
  --color-sliver: #B9B9B9;
  --color-shark: #2A2A2C;
  --color-dove-gray: #707070;
  --color-jumbo: #79797A;
  --color-nobel: #b6b6b6;
  --color-alabaster: #F9F9F9;
}

@include lg-max {
  :root {
    --header-height: 95px;
  }
}