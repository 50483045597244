.btn {
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    position: relative;
    z-index: 1;
    font-size: 19px;
    line-height: 1;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    border-radius: 5px;
    transition: all .3s ease-out;
  
    &--default {
        border-color: var(--color-white);
        color: var(--color-white);
        &:hover {
          background-color: var(--color-white);
          color: var(--color-tundora);
        }
    }
  
    &--primary {
      border-color: var(--color-dark);
      color: var(--color-dark);
      &:hover {
        background-color: var(--color-dark);
        color: var(--color-white);
      }
    }

    &--sm {
        height: 43px;
        font-size: 15px;
        min-width: 150px;
    }
  
    &--lg {
      height: 70px;
      min-width: 221px;
      font-size: 17px;
      letter-spacing: .2px;
      padding-left: 21px;
      padding-right: 21px;
      
      @include lg-max {
        min-width: 290px;
      }
    }
  
    &--xl {
      @include lg-min {
        height: 112px;
        min-width: 293px;
        font-size: 17px;
        line-height: 1;
        padding-left: 30px;
        padding-right: 30px;
        letter-spacing: .2px;
      }

      @include lg-max {
        height: 90px;
        padding-bottom: 3px;
        min-width: 233px;
        line-height: 1.4118;
        font-size: 17px;
      }
    }
  
    &--fluid {
      width: 100%;
      max-width: 100%;
      min-width: 0;
    }
  }

  .icon {
    &--fill-none {
      fill: none;
    }
    &--stroke-none {
      stroke: none;
    }

    &__svg {
      fill: currentColor;
      stroke: currentColor;
      &--btn-arrow {
        margin-left: 50px;
        margin-right: 2px;
        height: 30px;
        width: 31px;
        stroke-width: 6px;

        @include lg-max {
          margin-left: 27px;
          margin-right: 0;
        }
      }
    }
    .fill-none {
      fill: none;
    }
    .stroke-none {
      stroke: none;
    }
  }